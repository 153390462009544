import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Ripples from "react-ripples";
import logocaptainBixz from "../../assets/logo-captainBiz.png";
import "./Navbar.css";

export default function Navbar({
  firstname,
  lastname,
  companyname,
  login,
  userActivity,
  handleLog,
  firstInit,
  lastInit,
}) {
  const [utmTags, setUtmTags] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
     // Log query parameters from URL
      console.log("Query Parameters:", window.location.search);
      const queryParameters = new URLSearchParams(window.location.search);
      localStorage.setItem(
        "Utm_tags",
        decodeURIComponent(queryParameters.toString())
      );
      // Log utm_parameters to console
      console.log("utm_parameters:", queryParameters);
    const fetchUtmTags = () => {
      const storedUtmTags = localStorage.getItem("Utm_tags") ?? "";
      setUtmTags(storedUtmTags);
      setLoading(false);
    };

    fetchUtmTags();
  }, []);

  function myFunction() {
    document.getElementById("userProfile").classList.toggle("show");
  }

  return (
    <div className="header_cont_top">
      <div className="logo">
        <Link to={`/?${utmTags}`}>
          <img src={logocaptainBixz} alt="" />
        </Link>
      </div>
      <div className="menus">
        <div className={login}>
          <Ripples>
            <Link to={`/login/?${utmTags}`}>Login</Link>
          </Ripples>
        </div>
        <div className={userActivity}>
          <div className="name">
            <h3>
              {firstname} {lastname}
            </h3>
            <p>{companyname}</p>
          </div>
          <div className="smol_btn_cont">
            <button onClick={myFunction} className="smol_btn">
              <p>{firstInit} </p>
              <p>{lastInit} </p>
            </button>

            <div id="userProfile" className="userContent">
              <div className="userShowCase">
                <div className="smol_btn Big_btn">
                  <p>{firstInit} </p>
                  <p>{lastInit} </p>
                </div>
                <div className="fullName">
                  {firstname} {lastname}
                </div>
              </div>
              <div className="order_cont">
                <Ripples>
                  <Link to={`/order?${utmTags}`}>Order</Link>
                </Ripples>
              </div>
              <button onClick={handleLog}>Logout</button>
            </div>
          </div>
        </div>
      </div>
      {loading && <div>Loading...</div>}
    </div>
  );
}
